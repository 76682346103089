:root{
  --yellow: #F8DDA1;
  --red-light: #EB918E;
  --violet: #D3B2F1;
  --light-violet: #E7E8FA;
  --green: #8AB896;
  --light-yellow: #FEF9EC;
}

body, p, h2, h3 {
  margin: 0;
  padding: 0;

  font-family: 'Montserrat', Arial, sans-serif;
}

.container {
  width: 320px;
  margin: 0 auto;
}

.main-headline {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 39px;

  margin-bottom: 30px;
}


/* Auth-page */

.auth-page__description {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;

  margin-bottom: 35px;
}

.auth-forn__error-wrap {
  height: 25px;
  margin-bottom: 5px;
  padding-left: 5px;
  color: var(--red-light);
}

.auth-form__text-input {
  height: 50px;
  width: 320px;
  border: 2px solid #000;
  border-radius: 10px;
  font-size: 16px;
  padding-left: 15px;
  margin-bottom: 20px;

  box-sizing: border-box;
}

.auth-form__text-input::placeholder {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
}

.auth-form__submit-btn {
  display: block;
  position: relative;
  box-sizing: border-box;

  width: 320px;
  height: 60px;

  background: var(--yellow);
  border: 2px solid #000000;
  border-radius: 10px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;

  padding-left: 15px;

  text-align: left;
  cursor: pointer;
  -webkit-appearance: none;
}

.submit-btn__wrap {
  position: relative;
}

.submit-btn__wrap:hover {
  opacity: .6;
}

.submit-btn__wrap:active {
  opacity: .4;
}

.submit-btn__wrap::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  top: 17px;
  right: 15px;

  background-image: url("./img/right_green_arrow.svg");
  background-size: cover;
  z-index: 10;
  cursor: pointer;
}

.auth-page__main {
  min-height: 100vh;
  padding-top: 60px;

  background-image: url('./img/auth_bg.png');
  background-repeat: no-repeat;
  background-position: center bottom -10vh ;
  background-size: contain;
}


/* main page */

.main-page__wrap {
  background-color: var(--violet);
  border: 2px solid #000;
  border-radius: 40px 40px 0 0;
  padding: 50px 30px;

  min-height: calc(100vh - 260px);

  margin-left: -30px;
  margin-right: -30px;
}

.main-page__link-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-page__link-list li {
  margin: 0;
  padding: 0;
}

.main-page__link-item {
  text-decoration: none;
  display: block;
  box-sizing: border-box;

  width: 100%;
  padding: 15px;

  background-color: #fff;
  border: 2px solid #000;

  border-radius: 10px;
  margin-bottom: 25px;
}

.main-page__link-headline {
  margin-bottom: 5px;
  color: #000;
  font-weight: 700;

  font-size: 24px;
}

.main-page__link-description {
  font-size: 14px;
  width: 180px;
  color: #000;
}

.main-page__link-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrow__wrap {
  width: 38px;
  height: 38px;
}

img {
  width: 100%;
  height: auto;
}

/* review page */

.review__item {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #000;
  padding: 16px 20px;
  border-radius: 10px;
  margin-bottom: 40px;
  padding-bottom: 0;
}

.review-item__headline {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;

  margin-bottom: 7px;
}

.review-item__student {
  font-weight: 300;
  font-size: 14px;
}

.review-item__student--course {
  margin-bottom: 14px;
}

.review__author {
  font-weight: 200;
  margin-bottom: 16px;
}

.review-item__comment {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.review-item__comment {
  margin-bottom: 12px;
}

.review__file-link {
  display: block;
  padding: 12px 20px;

  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;

  color: #000000;
}

.review__file-wrap {
  background-color: var(--light-violet);
  box-sizing: border-box;
  width: 316px;
  /* margin-right: -18px; */
  margin-left: -20px;
  border-top: 2px solid #000;
  border-radius: 0 0 10px 10px;
  height: 42px;

  position: relative;
}

.review__file-wrap:hover {
  opacity: .6;
}

.review__file-wrap:active {
  opacity: .4;
}

.review__file-wrap::after {
  content: "";

  position: absolute;
  top: 10px;
  right: 16px;
  width: 22px;
  height: 22px;

  background-image: url('./img/yellow_arrow.svg');
  box-sizing: cover;

  pointer-events: none;
}

.review-page__main {
  padding-bottom: 50px;
}

/* Навигация */

.nav-bar__exit-btn {
  box-sizing: border-box;
  border: 2px solid #000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: var(--yellow);

  padding: 6px;
  padding-top: 5px;
  -webkit-appearance: none;
  cursor: pointer;
}

.nav-bar__exit-btn:hover {
  opacity: .6;
}

.nav-bar__exit-wrap {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
}

.nav-bar__balance-wrap {
  text-decoration: none;
  margin-right: auto;
  border-radius: 10px;
  border: 2px solid #000;

  padding: 5px 10px;
  background-color: var(--green);
  
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.nav-bar__balance-wrap:hover {
  opacity: .6;
}

.nav-bar__balance-wrap--negative {
  background-color: var(--red-light);
}

.nav-bar__balance-wrap--active {
  background-color: var(--yellow);
}

.transactions-list__item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  height: 45px;
  border: 2px solid #000;
  border-radius: 10px;
  margin-bottom: 8px;
  box-sizing: border-box;
}

.transactions-item__date {
  align-self: center;
  justify-self: center;
  height: 36px;
  width: 110px;
  box-sizing: border-box;
  border: 2px solid #000;
  background-color: var(--yellow);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.transactions-date__image {
  width: 23px;
  height: 23px;
  filter: invert(100%);
  margin-right: 4px;
}

.transactions__desc {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.transactions__amount {
  align-self: center;
  justify-self: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.transactions__amount--positive {
  color: var(--green)
}

.main-nav {
  background-color: var(--green);
  border-radius: 10px;
  min-height: 57px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  border: 2px solid #000;

  position: fixed;
  width: 320px;

  bottom: 20px;
  z-index: 5;
}

.nav-link__list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  justify-content: space-around;
}

.nav-list__item a{
  display: flex;
  align-items: center;
}

.nav-list__item-link img{
  width: 29px;
  height: 29px;
}

.nav-list__item-link {
  transition: background-color  0.3s;
}

.nav-list__item-link--active {
  padding: 2px 5px;
  margin-left: -5px;
  margin-right: -5px;
  background-color: var(--yellow);
  border-radius: 10px;
  border: 2px solid #000;
}

.nav-list__item-link--active img{
  width: 23px;
  height: 23px;
  filter: invert(100%);
}


/* Страница уроков */

.clock__wrap {
  margin-right: 6px;
  display: flex;
  align-items: center;
}

.lesson-item__clock-img-wrap {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}

.lesson-item__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--light-yellow);
}

.block-header {
  border-bottom: 2px solid;
  border-radius: 10px 10px 0 0 ;

  min-height: 44px;
  box-sizing: border-box;

  padding-left: 13px;
  padding-right: 13px;

  display: flex;
  align-items: center;
}

.lesson-item__footer {
  border-radius: 0 0 10px 10px;

  min-height: 42px;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 13px;
  padding-right: 13px;
}

.lesson-list__item {
  border-radius: 10px;
  border: 2px solid #000;
  margin-bottom: 20px;
}

.lesson-item__date {
  /* margin-right: 15px; */
  font-size: 16px;

  font-weight: 700;
}

.lessons-page {
  padding-bottom: 70px;
}

.lesson-item__price {
  font-weight: 700;
  color: var(--green);
  /* margin-left: auto; */
}

.lesson-item__price--bad {
  color: var(--red-light);
}

.lessons__child-select {
  border: 2px solid #000;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 25px;

  display: flex;
  flex-direction: column;
}

.lessons__child-select input {
  display: none;
}

.lessons__child-select input:checked+label {
  background-color: var(--violet);
}

.lessons__child-select label {
  display: block;
  cursor: pointer;
  padding: 10px;
  font-weight: 700;
  border-radius: 5px;
  margin-bottom: 5px;
}

.lessons__child-select label:hover {
  background-color: var(--light-violet);
}


/* Payment page */

.payment-page {
  padding-bottom: 100px;
}

.amout-radio__wrap input{
  display: none;
}

.amout-radio__wrap label {
  background-color: #fff;
  padding: 16px 0px;
  border: 2px solid #000;
  border-radius: 10px;
  display: block;
  width: 105px;
  text-align: center;
  box-sizing: border-box;
  font-weight: 900;

  cursor: pointer;
  transition: .3s;
  margin-bottom: 13px;
  margin-right: 10px;
}

.amout-radio__wrap--custom label {
  width: 160px;
  margin-bottom: 0;
  margin-right: 0;
}

.amout-radio__wrap label:hover {
  opacity: .6;
}

.amout-radio__wrap label:active {
  opacity: .4;
} 

.amout-radio__wrap input[type="radio"]:checked+label {
  background-color: var(--green);
}

.payment__amount-variant {
  display: flex;
  flex-wrap: wrap;
}

.payment__amount-number {
  display: block;
  border: 2px solid #000;
  border-radius: 10px;
  padding: 15px 25px;
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  font-size: 16px;
  font-weight: 700;
}

.payment__amount-number::-webkit-outer-spin-button,
.payment__amount-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
}


.payment__sbp-form {
  border: 2px solid #000;
  border-radius: 10px;
  margin-bottom: 10px;
}

.payment-form__headline {
  font-weight: 900;
  font-size: 24px;
  margin-bottom: 15px;
}

.payment-form__description {
  font-weight: 200;
  font-size: 16px;
}

.payment-form__header-wrap {
  padding: 10px 20px;
  border-bottom: 2px solid #000;
  background-color: var(--light-yellow);
  border-radius: 10px;
  padding-bottom: 20px;
}

.payment__submit-wrap {
  display: flex;

  padding: 15px;
  align-items: center;
  justify-content: center;
}

.payment__submit-btn {
  font-family: 'Montserrat', Arial, sans-serif;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-weight: 900;
  font-size: 16px;
  justify-content: center;

  background-color: var(--yellow);
  border: 2px solid #000;
  
  height: 52px;
  width: 236px;
  box-sizing: border-box;

  cursor: pointer;

  -webkit-appearance: none;
}

.payment__submit-btn:hover {
  opacity: .6;
}

.payment__submit-btn:active {
  opacity: .4;
}

.payment__privacy-text {
  padding: 10px;
  font-size: 10px;
  padding-top: 0;
  text-align: center;
}

.schedule__wrap {
  margin-bottom: 36px;
}

.schedule-item__wrap {
  width: 100%;
  border: 2px solid #000;
  border-radius: 10px;
  margin-bottom: 24px;
}

.schedule-header {
  background-color: var(--violet);

  border-bottom: 2px solid;
  border-radius: 8px 8px 0 0 ;
}

.schedule__day-wrap {
  padding: 8px 0;
}

@media screen and (max-width: 680px) {
  .container {
    width: 320px;
  }
}


@media screen and (max-width: 480px) {
  .container {
    width: 320px;
  }
}